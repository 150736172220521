.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  background-color: #f1f1f1;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.copyright{
  flex: 0 0 auto;
  font-size: calc(4px + 1vmin);
  padding: 12px;
  color: #000;
  opacity: 0.7;
}

.App-header {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

